<template>
  <div class="bank-transactions" :class="{'in-modal': bankId > 0, 'fixed-draggable-dynamic-table-wrapper-height': bankId === 0}">
    <draggable-dynamic-table ref="bankTransactions"
                             class="bank-transactions"
                             :class="{'top-zero-radius': bankId > 0}"
                             :in-modal="bankId > 0"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @load:more="getTransactions"/>

    <button id="printBankTransactions" v-show="false" @click="handlePrintBankTransactions"></button>
  </div>
</template>

<script>
import axios from "axios";
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getBank, getBankTransactions, printBankTransactions} from '@/http/requests/treasury/banks'
import {convertUtcToLocale, getTimeFromServer, hideLoading, showLoading} from "../../../../../../assets/js/functions";
import setDocumentDescription from '../../../../../../mixins/setDocumentDescription'

export default {
  name: 'bankTransactions',
  components: {DraggableDynamicTable},
  metaInfo () {
    return {
      title: this.bank ? this.$t('treasury.banks.transactions.dynamicTitle', {bank: this.bank.name}) : this.$t('treasury.banks.transactions.title')
    }
  },
  mixins: [setDocumentDescription],
  props: {
    bankId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      options: {
        id: 'bankTransActions',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'transactions.table.header.balance',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'price',
          i18n: 'transactions.table.header.price',
          width: '120px',
          minWidth: 120,
          sortable: true,
          locked: true
        },
        {
          field: 'description',
          i18n: 'transactions.table.header.description',
          width: '130px',
          minWidth: 130,
          locked: true,
          sortable: true
        },
        {
          field: 'date',
          i18n: 'transactions.table.header.date',
          align: 'center',
          width: '120px',
          minWidth: 120,
          sortable: true
        },
        {
          field: 'docNumber',
          i18n: 'transactions.table.header.docNumber',
          width: '80px',
          minWidth: 80,
          align: 'center',
          locked: true,
          sortable: true,
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      bank: null,
      data: [],
      filters: {},
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      loadingTimer: 0,
      endedList: false,
      actions: [
        {
          toolbar: [],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            },
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'transactionsTreasuryBank' ? 'treasuryBank' : 'treasuryBanks'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.$route.name === 'treasuryBank') {
      this.actions[0].toolbar.push({
        id: 'editBank',
        icon: 'EDIT',
        iconPack: 'useral',
        color: 'warning',
        permission: 'bank.update'
      })
    }
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    // this.$vs.loading()
    this.getBank()
    this.getTransactions()
  },
  methods: {
    getBank () {
      getBank(this.bankId || this.$route.params.id).then(response => {
        this.bank = response.data.data
        if (!this._isBeingDestroyed) {
          this.$store.dispatch('setPageTitle', this.$t('treasury.banks.transactions.dynamicTitle', {bank: this.bank.name}))
        }
      })
    },
    getTransactions () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.bankTransactions && this.data.length === 0) {
            this.$refs.bankTransactions.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.bankTransactions && this.data.length > 0) {
            this.$refs.bankTransactions.loadMoreStatus = 'Loading'
          }

          getBankTransactions(this.bankId || this.$route.params.id, this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0

            const transactions = response.data.data
            transactions.forEach(transaction => {
              if (transaction.document.reference) transaction.document.reference.reference_type = transaction.document.reference_type

              this.data.push({
                docNumber: {
                  value: transaction.document.id,
                  route: {name: 'document', params: {id: transaction.document.id}},
                  target: '_blank'
                },
                date: {
                  value: convertUtcToLocale(transaction.document.date, this.$localeValidator('moment.dateTime')),
                  classes: this.$vs.rtl ? 'rtl' : 'ltr'
                },
                description: this.generateDocumentDescription(transaction.document, {hasRoute: true}),
                price: {
                  type: 'price',
                  classes: transaction.price < 0 ? 'text-danger' : 'text-success',
                  value: Math.abs(transaction.price)
                },
                balance: {
                  type: 'price',
                  classes: transaction.balance < 0 ? 'text-danger' : 'text-success',
                  value: Math.abs(transaction.balance)
                }
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('docNumber')

              this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
            }

            if (this.$refs.bankTransactions) this.$refs.bankTransactions.loadMoreStatus = ''
          }).catch((error) => {
            console.log(error)
            if (this.$refs.bankTransactions && !axios.isCancel(error)) this.$refs.bankTransactions.loadMoreStatus = 'Danger'
          })
        }
      }, 500)
    },

    handlePrintBankTransactions () {
      showLoading()

      printBankTransactions(this.bankId || this.$route.params.id, this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `bankTransactionList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('treasury.banks.transactions.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bank-transactions {
    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
